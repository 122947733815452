import { BOTTLES } from '../assets/images/images.config.js';
import React from 'react';


const SLIDER_ELEMENTS = [
    {
        title: 'CONTRA',
        image: BOTTLES.contra_bottle,
        description: <p>O seu trabalho tem raízes em diferentes áreas, desde o graffiti à arte abstrata. Contra é membro integrante e co-fundador do Colectivo Rua.</p>,
        instagram: 'https://www.instagram.com/contra.rua/'
    },
    {
        title: 'THE CAVER',
        image: BOTTLES.caver_bottle,
        description: <p>Recorrendo a formas simples e contrastantes, em composições complexa de conteúdo, The Caver sublinha estilo próprio no seu traço, ora como ilustrador, tatuador ou designer.</p>,
        instagram: 'https://www.instagram.com/_thecaver_/'
    },
    {
        title: 'FRAME01',
        image: BOTTLES.frame_bottle,
        description: <p>O interesse pela arte urbana começa a ser notório quando inicia a atividade em pintura em <span style={{fontStyle: 'italic'}}>spray</span> e integra a crew MDK, originária do seu bairro na Margem Sul.</p>,
        instagram: 'https://www.instagram.com/luis_baldini/'
    },
    {
        title: 'CATARINA GLAM',
        image: BOTTLES.glam_bottle,
        description: <p>Dedica-se à criação de esculturas, instalações públicas em madeira e pinturas de murais. Com traços precisos e vértices vincados, as personagens geométricas já se tornaram o cunho da artista.</p>,
        instagram: 'https://www.instagram.com/catarinaglam/'
    },
    {
        title: "KRUELLA D'ENFER",
        image: BOTTLES.kruella_bottle,
        description: <p>Plenas de seres fantásticos, as obras de Kruella suscitam um profundo deslumbre, dando vida a lendas e mitos ancestrais através de cores contrastantes e formas geométricas.</p>,
        instagram: 'https://www.instagram.com/kruelladenfer/'
    },
    {
        title: 'MAR',
        image: BOTTLES.mar_bottle,
        description: <p>Conhecido no meio pelos seus bonecos ou characters, destaca-se pela forma como constrói as suas personagens e ambientes, incutindo-lhes linhas e formas que os tornam únicos.</p>,
        instagram: 'https://www.instagram.com/goncalomar1/?hl=en'
    },
    {
        title: 'MÁRIO BELÉM',  
        image: BOTTLES.belem_bottle,
        description: <p>Da pintura sobre madeira em composições esculturais aos grandes murais, justapõe com frequência a imagem e a palavra, compondo narrativas visuais entre o familiar e o inusitado.</p>,
        instagram: 'https://www.instagram.com/mariobelem/?hl=en'
    },
    {
        title: 'MOSAIK',
        image: BOTTLES.mosaik_bottle,
        description: <p>Conhecido pela sua precisão nos traços e limpeza nas peças, desenvolveu uma avançada caligrafia urbana que o distingue dos demais. O artista tem colaborado com vários projetos internacionais.</p>,
        instagram: 'https://www.instagram.com/mosaikone/'
    },
    {
        title: 'SAMINA',
        image: BOTTLES.samina_bottle,
        description: <p>Entre momentos de equilíbrio ou de conflito, o trabalho de Samina procura diálogos com os contextos e matéria urbana onde se insere, procurando confrontar a representação humana com a composição geométrica.</p>,
        instagram: 'https://www.instagram.com/j_samina/?hl=en'
    },

]


export {
    SLIDER_ELEMENTS
}