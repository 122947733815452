// REACT IMPORT
import React from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from './views/home/home.view.js';
// import Blocker from './views/blocker/blocker.view.js';
import Age from './views/age/age.view.js';

// STYLE IMPORT
import './assets/css/App.css';

class App extends React.Component {

    render(){
      return(
        <Router>
          <Switch>
            {/* <Route exact path="/"> <Blocker/> </Route> */}
            <Route path="/"> <Home/> </Route>
            <Route path="/check-age"> <Age/> </Route>
          </Switch>
      </Router>
      );
    }
}
export default App;