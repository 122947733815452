import React, { useEffect, useState } from 'react';
import './cookiebar.style.css';

function CookieBar (props) {

    const[bar, setBar] = useState(props.hide);

    useEffect(() => {
        setBar(props.hide);
    }, [props.hide]);

        return(
            <div className="cookiebar-main-container" style={{display: bar == 1 ? 'none' : 'flex'}}>
                <div className="cookiebar-text-containter">
                    <div className="cookiebar-title-containter">ESTE SITE UTILIZA COOKIES</div>
                    <div className="cookiebar-subtitle-containter">Utilizamos cookies para melhorar a experiência do utilizador e analisar o tráfego nos websites. Consentes? Para mais informações sobre cookies e o processamento dos seus dados pessoais, consulta a nossa <a href='https://colecaocoruja.com/docs/cookies.pdf' target="_blank" rel="noopener noreferrer">Política de Cookies</a> e a nossa <a href='https://colecaocoruja.com/docs/privacidade.pdf' target="_blank" rel="noopener noreferrer"> Política de Privacidade</a></div>
                </div>
                <div className="cookiebar-action-containter" onClick={() => {props.callback()}}>
                    <div className="cookiebar-action-button">ACEITO</div>
                </div>
            </div>
        );

    
}

export default CookieBar;