import React, { useEffect, useState }  from 'react';
import { useHistory } from "react-router-dom";
import { useCookies } from 'react-cookie';

import { GIF } from '../../assets/gif/gif.config.js'
import './age.style.css';


function Age (props) {
    const [isNotValid, setIsNotValid] = useState(false);
    const history = useHistory();
    const [cookies] = useCookies(['age']);
    const [isAge, setisAge] = useState(true);

    useEffect(() => {
        if(cookies.age == 1){
            setisAge(false);
        }
      }, [cookies.age]);

    function onConfirmationHandler  (value, history) {
        if(value){
            setisAge(false);
        }
    }

    return(
            <div className="app-container" style={{display: isAge ? 'flex' : 'none'}}>
                <div className="age-conatiner top">
                <div className="age-selection-container">
                    <p className="age-selection-subtitle">TEMOS DE PERGUNTAR</p>
                    <p className="age-selection-title">TENS MAIS DE 18 ANOS?</p>
                    <div className="age-selection-option" style={{display: isNotValid ? 'none' : 'flex'}}>
                        <div className="age-selection-button" onClick={() => setIsNotValid(true) }>NÃO</div>
                        <div className="age-selection-button" onClick={() => onConfirmationHandler(true, history) }>SIM</div>
                    </div>
                    <div className="age-selection-option" style={{display: isNotValid ? 'flex' : 'none'}}>
                        <p className="age-selection-warning">SEM IDADE MÍNIMA PARA ENTRAR NO SITE</p>
                    </div>
                </div>
                </div>
                <div className="age-conatiner bottom">
                    <img src={GIF.coruja} alt=""/>
                </div>
            </div>
        );
    
}

export default Age;