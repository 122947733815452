const IMAGES = {
    coruja_logo: require('./coruja_logo.png'),
    iphone_frame: require('./iphone_frame.png'),
    android_frame: require('./android_frame.png'),
    apple_store: require('./apple_store.png'),
    google_store: require('./google_store.png'),
    dott_logo: require('./dott.png'),
    dott_logo_white: require('./dott-white.png'),
}

const BOTTLES = {
    contra_bottle: require('./CUT_CONTRA.png'),
    caver_bottle: require('./CUT_CAVER.png'),
    frame_bottle: require('./CUT_FRAME.png'),
    glam_bottle: require('./CUT_GLAM.png'),
    kruella_bottle: require('./CUT_KRUELLA.png'),
    mar_bottle: require('./CUT_MAR.png'),
    belem_bottle: require('./CUT_MARIO-BELEM.png'),
    mosaik_bottle: require('./CUT_MOSAIK.png'),
    samina_bottle: require('./CUT_SAMINA.png'),
}

export {
    IMAGES, BOTTLES
}