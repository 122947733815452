// REACT IMPORT
import React, { useEffect, useState }  from 'react';
// NPM MODULES IMPORT
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icon from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import Carousel from 'react-bootstrap/Carousel';
import { useCookies } from 'react-cookie';
import firebase from 'firebase';
// COMPONENT IMPORT
import CookieBar from '../../components/CookieBar/cookiebar.component.js';
// ASSETS IMPORT
import { IMAGES } from '../../assets/images/images.config.js';
import { SVG } from '../../assets/svg/svg.config.js';
import { SLIDER_ELEMENTS } from '../../config/page.config.js';
import Age from '../../views/age/age.view.js';
// STYLE IMPORT
import './home.style.css';

const firebaseConfig = {
  apiKey: "AIzaSyAN212badlXNaNj69FxQIICRlfmqvcvRT8",
  authDomain: "corujaar-superbock.firebaseapp.com",
  databaseURL: "https://corujaar-superbock.firebaseio.com",
  projectId: "corujaar-superbock",
  storageBucket: "corujaar-superbock.appspot.com",
  messagingSenderId: "796427603050",
  appId: "1:796427603050:web:e95667feac2cb483c20e13",
  measurementId: "G-EPSLMT5WJS"
};

function Home () {
  
  const [isAge, setisAge] = useState(true);
  const [isAndroid, setIsAndroid] = useState(true);
  const [isiOS, setIsiOS] = useState(true);
  const [isOverAndroid, setIsOverAndroid] = useState(false);
  const [isOverIOS, setIsOverIOS] = useState(false);
  const [isOverDott, setIsOverDott] = useState(false);
  const [cookies, setCookie] = useCookies(['age', 'cookies']);
  const [analytics, setAnalytics] = useState('');
  

  useEffect(() => {
  
    if(cookies.age == 1){
      setisAge(false)
    }

    if(cookies.cookies == 1){
      const fireInit = firebase.initializeApp(firebaseConfig);
      setAnalytics(fireInit.analytics());
    }

    checkDeviceOS();
  }, [cookies]);
 
  function acceptCookies () {
    setCookie('cookies', 1, { path: '/' });
    setCookie('age', 1, { path: '/' });
  }

  const checkDeviceOS = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      setIsAndroid(true);
      setIsiOS(true);
    }
    if (/android/i.test(userAgent)) {
      setIsAndroid(true);
      setIsiOS(false);
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsAndroid(false);
      setIsiOS(true);
    }
  }

  function onClickHandle (eventName, button) {
    if(cookies.cookies == 1)
      analytics.logEvent(eventName, { button: button}); 
  }

  const handleSelect = (selectedIndex, e) => {
    if(cookies.cookies == 1)
      analytics.logEvent('Slider', { action: 'ChangeSlideLabel'});
  };


      return(
          <div className="app-container">

            <Age hide={isAge}/>
              
              <div className="header-container">
                <img className="logo" src={IMAGES.coruja_logo} alt=""/> 
                <div className="header-social-container">
                  <div className="header-social-button" onClick={() => { onClickHandle('ButtonClick', 'Facebook'); window.open('https://www.facebook.com/corujasuperbock');}}><FontAwesomeIcon icon={faFacebookF} className="header-social-icon"/><span class="tooltiptext">Facebook</span></div>
                  <div className="header-social-button" onClick={() => { onClickHandle('ButtonClick', 'Instagram'); window.open('https://www.instagram.com/corujasuperbock');}}><FontAwesomeIcon icon={faInstagram} className="header-social-icon"/><span class="tooltiptext">Instagram</span></div>
                  <div className="header-social-button" onClick={() => { onClickHandle('ButtonClick', 'SuperBock'); window.open('https://www.superbockcoruja.pt/');}}><FontAwesomeIcon icon={Icon['faGlobe']} className="header-social-icon"/><span class="tooltiptext">Website</span></div>
                  <div className="header-social-button" onClick={() => { onClickHandle('ButtonClick', 'Dott'); window.open('https://dott.pt/pt/store/super-bock?brands=Super%20Bock%20Coruja');}} onMouseOver={()=> setIsOverDott(true) } onMouseLeave={()=> setIsOverDott(false)}><img src={isOverDott ? IMAGES.dott_logo_white : IMAGES.dott_logo} alt=""/><span class="tooltiptext">Encomenda a tua Coruja no Dott</span></div>
                </div>
              </div>

            <section className="section-container" style={{height: window.innerHeight-90}}>
             
              <div className="intro-main-container">
                <div className="intro-image-container" style={{height: window.innerHeight/2.6}}>
                  <img src={isiOS ? IMAGES.iphone_frame : IMAGES.android_frame} alt=""/>
                  <video muted autoPlay loop playsInline>
                    <source src={require('../../assets/video/video_intro.mp4')} type="video/mp4" />
                  </video>
                </div>
                <div className="intro-description-container">
                  <div className="intro-description-text">
                    <p>Descarrega a App Coleção Coruja, aponta para o rótulo e entra numa experiência de realidade aumentada.</p>
                  </div>
                  <div className="intro-description-stores">   
                    <img onClick={()=> {onClickHandle('ButtonClick', 'GooglePlay'); window.open('https://play.google.com/store/apps/details?id=com.colecaocoruja.ar');}} onMouseOver={()=> setIsOverAndroid(true) } onMouseLeave={()=> setIsOverAndroid(false) } src={isOverAndroid ? SVG.play_store_over : SVG.play_store} style={{display: isAndroid ? 'flex' : 'none'}} alt=""/>
                    <img onClick={()=> {onClickHandle('ButtonClick', 'AppleStore'); window.open('https://apps.apple.com/pt/app/id1500653855');}} onMouseOver={()=> setIsOverIOS(true) } onMouseLeave={()=> setIsOverIOS(false)} src={isOverIOS ? SVG.app_store_over : SVG.app_store} style={{display: isiOS ? 'flex' : 'none'}} alt=""/>
                  </div>
                </div>
              </div>

              
              <div className="scroller"><a><span><span className="scroll-down"></span></span>Descobre os artistas</a></div>
            

            </section>


            <section id="products">
              <div className="product-conatiner">

                <Carousel interval={0} onSelect={handleSelect} nextIcon={<span className="icon-right"></span>} prevIcon={<span className="icon-left"></span>}>
                  {
                    SLIDER_ELEMENTS.map((prop, key) => {
                      return(
                        <Carousel.Item key={key}>
                          <div className="product-main-container">
                            
                            <div className="product-description-container">
                              <div className="product-description-text">
                                <div className="product-description-top-bar">
                                  <h1>{prop.title}</h1>
                                </div>
                                {prop.description}
                                {/* <p className="url" style={{cursor: 'pointer', color: 'rgb(229,219,194)', textDecoration: 'underline'}} onClick={() => { analytics.logEvent('ButtonClick', { button: prop.title+"-Instagram" }); window.open(prop.instagram);}}>{prop.instagram}</p> */}
                                  <div className="product-description-social"> 
                                    <div className="product-description-social-button" onClick={() => {onClickHandle('ButtonClick', prop.title+"-Instagram"); window.open(prop.instagram);}}><FontAwesomeIcon icon={faInstagram} className="product-description-social-icon"/></div>
                                  </div>
                              </div>
                            </div>

                            <div className="product-image-container">
                              <img className="product-image-noraml" src={prop.image} alt=""/>
                            </div>
                          </div>
                        </Carousel.Item>
                      );
                    })
                  }
                </Carousel>

                </div>

                <div className="footer-container">
                  <div className="footer-description-text">
                    <h1>Começa a tua experiência AR aqui!</h1>
                  </div>
                  <div className="footer-description-stores">  
                    <img onClick={()=> {onClickHandle('ButtonClick', 'GooglePlay'); window.open('https://play.google.com/store/apps/details?id=com.colecaocoruja.ar');}} onMouseOver={()=> setIsOverAndroid(true) } onMouseLeave={()=> setIsOverAndroid(false) } src={isOverAndroid ? SVG.play_store_over : SVG.play_store} style={{display: isAndroid ? 'flex' : 'none'}} alt=""/>
                    <img onClick={()=> {onClickHandle('ButtonClick', 'AppleStore'); window.open('https://apps.apple.com/pt/app/id1500653855');}} onMouseOver={()=> setIsOverIOS(true) } onMouseLeave={()=> setIsOverIOS(false)} src={isOverIOS ? SVG.app_store_over : SVG.app_store} style={{display: isiOS ? 'flex' : 'none'}} alt=""/>
                  </div>
                  <div className="footer-politics-conatiner"> 
                    {/* <a href='https://colocaocoruja.com/docs/informacaolegal.pdf' target="_blank" rel="noopener noreferrer">Informação Legal</a> */}
                    <a onClick={()=> {onClickHandle('ButtonClick', 'PolIticaPrivacidade');}} href='https://colecaocoruja.com/docs/privacidade.pdf' target="_blank" rel="noopener noreferrer">Política de Privacidade</a>
                  </div>
                </div>
            </section>

            <CookieBar hide={cookies.cookies} callback={() => {acceptCookies()}}/>
          </div>
      );
    
}
export default Home;